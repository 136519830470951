import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/Project.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When organizing programming competitions, it happens quite often that I need to
send an email to a fixed list of, say, a few hundred contacts: we might want to
send the access credentials to each participant, or even simply communicate the
starting time of the contest.`}</p>
    <p>{`Traditionally, the method we used was to have a big Python script which parsed a
CSV file and formatted some hardcoded string with all the necessary information
and finally sent that string over email. This method had two big issues:`}</p>
    <ul>
      <li parentName="ul">{`It's hard and repetitive: to send a new email we needed to modify the
hardcoded data in the Python script.`}</li>
      <li parentName="ul">{`It's error-prone: at some point, we had at least 3 variations of the same
script with slight differences. Good luck picking the correct one!`}</li>
    </ul>
    <p>{`So, one day, I set out to make our life easier and wrote Mailipy.`}</p>
    <h3>{`Installation`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`sudo pip install mailipy
`}</code></pre>
    <h3>{`Generating emails`}</h3>
    <p>{`The first step is to write a template, using the “`}<em parentName="p">{`Markdown + YAML front
matter`}</em>{`” syntax. It should look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`---
from:     "My Name <mail@example.com>"
to:       "{{Email}}"
subject:  "Some very interesting subject"
attach:   ["Rules.pdf"]
---

Hi {{Name}},

Welcome to the Contest! You will find a PDF with the rules attached to this email.

Here are the credentials you need to login in the contest:

- Username: {{Username}}
- Password: {{Password}}

Regards,  
The Staff.
`}</code></pre>
    <p>{`Then we can generate the emails, assuming we already have our `}<inlineCode parentName="p">{`contacts.csv`}</inlineCode>{`
file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ mailipy-gen template.md contacts.csv
`}</code></pre>
    <p>{`The emails are `}<strong parentName="p">{`only generated`}</strong>{`, but not sent yet! This can be very useful,
because it gives us a chance to inspect the email files (with `}<inlineCode parentName="p">{`.eml`}</inlineCode>{` extension)
for errors. No more sending test emails to ourselves ✨`}</p>
    <h3>{`Sending the email`}</h3>
    <p>{`Once we confirm the `}<inlineCode parentName="p">{`outbox`}</inlineCode>{` folder looks good, we can bulk-send all emails with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ mailipy-send mail.example.com:528 my_username outbox/
`}</code></pre>
    <p>{`This tool has definitely improved our workflow. `}<a parentName="p" {...{
        "href": "https://github.com/wil93/mailipy"
      }}>{`Check it out on
Github!`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      